<script lang="ts">
export default defineComponent({
  props: {
    heading: { type: String, required: true },
    body: { type: String, required: true },
  },
});
</script>

<template>
  <v-container>
    <h2 class="text-2xl mb-6 font-bold text-gray-900">
      {{ heading }}
    </h2>
    <div v-if="body" v-html="body" class="prose text-gray-900 max-w-none" />
  </v-container>
</template>
